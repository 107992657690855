import { on } from 'delegated-events'

on('click', '.add-vschema-tables', function (event) {
  const input = document.querySelector('.vschema-json')
  const content = input.value

  const parsedContent = JSON.parse(content)
  if (!parsedContent['tables']) {
    parsedContent['tables'] = {}
  }

  const tables = input.getAttribute('data-tables')
  if (tables) {
    try {
      const parsedTables = JSON.parse(tables)
      parsedTables.forEach((table) => {
        if (!parsedContent['tables'][table]) {
          parsedContent['tables'][table] = {}
        }
      })

      const text = JSON.stringify(parsedContent, null, 2)
      input.value = text
    } catch {
      // Don't do anything if tables is not parseable
    }
  }
  
})
